import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import HomeEn from './pages/HomeEn';
import NotFound from './pages/NotFound';
import NotInfo from './components/NotInfo';
import Company from './pages/Company';
import CompanyEn from './pages/CompanyEn';
import Cooperation from './pages/Cooperation';
import CooperationEn from './pages/CooperationEn';
import Career from './pages/Career';
import Services from './pages/Services';
import ServicesEn from './pages/ServicesEn';
import CareerEn from './pages/CareerEn';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/en" element={<HomeEn />} />
      <Route path="/company" element={<Company />} />
      <Route path="/companyEn" element={<CompanyEn />} />
      <Route path="/cooperation" element={<Cooperation />} />
      <Route path="/cooperationEn" element={<CooperationEn />} />
      <Route path="/career" element={<Career />} />
      <Route path="/careerEn" element={<CareerEn />} />
      <Route path="/services" element={<Services />} />
      <Route path="/servicesEn" element={<ServicesEn />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/pumps" element={<NotInfo />} />
      <Route path="/spareparts" element={<NotInfo />} />
      <Route path="/audit" element={<NotInfo />} />
      <Route path="/repair" element={<NotInfo />} />
      <Route path="/watersupply" element={<NotInfo />} />
      <Route path="/industry" element={<NotInfo />} />
    </Routes>
  );
}

export default App;
