import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const NotFound = () => {
  return (
    <div className="wrapper">
      <Header />
      <main>
        <section class="not-found">
          <p>Данной страницы не существует</p>
          <Link class="not-found-link" to="/">
            Вернуться на главную
          </Link>
        </section>
      </main>
    </div>
  );
};

export default NotFound;
