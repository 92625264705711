import React from 'react';
import policy from '../assets/politika.pdf';
import agree from '../assets/soglasie.pdf';

const Form = ({ active, setActive }) => {
  console.log(active);
  return (
    <div className={active ? 'active__form' : 'modal'} id="form" onClick={() => setActive(false)}>
      <div className="form__container" onClick={(el) => el.stopPropagation()}>
        <h2>Connect with us</h2>
        <form method="POST">
          <input id="name" type="text" placeholder="Name*" />
          <input id="email" type="text" placeholder="E-mail*" />
          <input id="phone" type="text" placeholder="Phone*" />
          <span>
            By clicking on the “Send” button you{' '}
            <a target="_blank" href={agree}>
              consent
            </a>{' '}
            to the processing of personal data and{' '}
            <a target="_blank" href={policy}>
              agree
            </a>{' '}
            to the policy regarding the processing of personal data
          </span>
          <input id="bnt" className="button" type="submit" value="Send" />
        </form>
        <button className="form__close" onClick={() => setActive(false)}></button>
      </div>
    </div>
  );
};

export default Form;
