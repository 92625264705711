import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__info">
          <a className="footer__link" href="#">
            Политика конфиденциальности
          </a>
          <a className="footer__link" href="tel:+73472572057">
            +7 347 25 72 057{' '}
          </a>
          <a className="footer__link" href="mailto:inotek@internet.ru">
            inotek@internet.ru
          </a>
        </div>
        <div className="footer__dev">
          <span>Сайт создан -</span>
          <a href="https://virtek.pro/">
            <img
              className="footer__img"
              src="https://virtek.pro/static/media/newLogo.0d81460c4383bb1b1e44827bf5ba4775.svg"
              alt="Логотип Virtek"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
