import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Career = () => {
  const classes = useStyles();
  const [modalActive, setModalActive] = React.useState(false);
  return (
    <div className="wrapper">
      <header class="header">
        <div class="header__container container">
          <Link to="/" aria-label="Вернуться на главную страницу">
            <svg
              width="150"
              height="33"
              viewBox="0 0 150 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 7.96543V15.6937L3.70501 19.8598L7.41002 24.0258V12.1315V0.237127H3.70501H0V7.96543Z"
                fill="#6c757d"
              />
              <path
                d="M16.4079 8.87107V17.4447L12.0148 22.456C9.63303 25.173 7.41002 27.4069 7.14538 27.4069C6.88073 27.4069 5.18701 25.5956 3.38744 23.3616L0.0529287 19.256L0 22.8786C0 26.4409 0.0529287 26.622 2.54058 29.3994C4.71066 31.8145 5.3458 32.2371 7.19831 32.2371C9.26253 32.2371 9.63303 31.8748 16.5667 23.8447L23.8179 15.4522V7.84465V0.237106H20.1129H16.4079V8.87107Z"
                fill="#6c757d"
              />
              <path
                d="M26.9937 6.39562V12.5541L30.6987 8.38807C33.7685 4.88618 34.4037 3.85977 34.4037 2.22958V0.237127H30.6987H26.9937V6.39562Z"
                fill="#6c757d"
              />
              <path
                d="M42.8723 5.67107V11.105H38.638C34.6154 11.105 34.4037 11.0447 34.4037 9.77673C34.4037 8.62956 33.7685 9.11258 30.6987 12.6145L26.9937 16.7805V24.5088V32.2371H30.6987H34.4037V24.0862V15.9352H38.638H42.8723V19.9805V24.0258L46.5773 19.8597L50.2823 15.6937V7.96541V0.237106H46.5773H42.8723V5.67107Z"
                fill="#6c757d"
              />
              <path
                d="M56.2103 2.83332C54.0932 5.30879 53.9873 5.61068 53.9873 8.99181V12.5541L57.3218 8.81068L60.6563 5.06729H65.2611H69.8659V14.5465V24.0862L73.5709 19.7994L77.2759 15.4522V10.6824V5.91257L74.7354 3.07483L72.1948 0.237097H65.3141H58.4333L56.2103 2.83332Z"
                fill="#6c757d"
              />
              <path
                d="M79.9224 2.65222V5.06732H83.892H87.8617V8.81071V12.5541L91.1962 8.81071L94.5307 5.06732H98.8708H103.211V2.65222V0.237127H91.5667H79.9224V2.65222Z"
                fill="#6c757d"
              />
              <path
                d="M106.387 2.65219V5.06728H111.415H116.443V14.2446V23.422L120.148 19.256L123.853 15.0899V10.5012V5.91257L121.313 3.07483L118.772 0.237097H112.579H106.387V2.65219Z"
                fill="#6c757d"
              />
              <path
                d="M127.029 6.39562V12.5541L130.734 8.38807C133.804 4.88618 134.439 3.85977 134.439 2.22958V0.237127H130.734H127.029V6.39562Z"
                fill="#6c757d"
              />
              <path
                d="M140.367 4.64467L136.556 8.99184V12.4937V15.9352H139.732H142.908V19.9805V24.0258L146.348 20.1617L149.788 16.2975L147.565 13.7013C145.872 11.7088 144.919 11.1051 143.278 10.9843L141.214 10.8032L143.966 7.48241C145.501 5.67109 147.459 3.25599 148.359 2.1692L150 0.237127H147.089C144.231 0.237127 144.178 0.237127 140.367 4.64467Z"
                fill="#6c757d"
              />
              <path
                d="M57.3218 12.856L54.0402 16.8409V21.6711L53.9873 26.5013L56.422 29.339L58.8038 32.2371H65.5787H72.3006L74.7883 29.1579C77.0642 26.3805 77.2759 25.7767 77.2759 23.1201C77.2759 21.4899 77.1172 20.1616 76.9054 20.1616C76.6937 20.1616 75.1588 21.7918 73.518 23.7843L70.5011 27.4673L65.8433 27.2862L61.1327 27.105L60.868 17.9881L60.6034 8.87107L57.3218 12.856Z"
                fill="#6c757d"
              />
              <path
                d="M91.4078 12.7956L87.8616 16.9013V24.5692V32.2371H91.5666H95.2716V20.4635C95.2716 14.0031 95.2187 8.68994 95.1128 8.68994C95.0599 8.68994 93.3662 10.5013 91.4078 12.7956Z"
                fill="#6c757d"
              />
              <path
                d="M130.575 12.7956L127.029 16.9013V24.5692V32.2371H130.734H134.439V20.4635C134.439 14.0031 134.386 8.68994 134.28 8.68994C134.227 8.68994 132.533 10.5013 130.575 12.7956Z"
                fill="#6c757d"
              />
              <path
                d="M106.387 13.5201V15.9352H110.092H113.797V13.5201V11.105H110.092H106.387V13.5201Z"
                fill="#6c757d"
              />
              <path
                d="M120.148 23.4824L116.761 27.4069H111.574H106.387V29.822V32.2371H112.685H118.931L121.418 29.2182C123.694 26.4409 123.853 26.0786 123.853 22.8786C123.853 21.0673 123.8 19.5579 123.694 19.5579C123.641 19.5579 122.001 21.3088 120.148 23.4824Z"
                fill="#6c757d"
              />
              <path
                d="M19.6895 23.9654C16.8314 27.2258 16.408 28.0711 16.408 30.0031V32.2371H20.113H23.818V26.1994C23.818 22.8786 23.6063 20.1616 23.3946 20.1616C23.1828 20.1616 21.4891 21.8522 19.6895 23.9654Z"
                fill="#6c757d"
              />
              <path
                d="M46.4185 24.2673C43.5075 27.5881 42.8723 28.6748 42.8723 30.305V32.2371H46.5773H50.2823V26.1994C50.2823 22.8786 50.2294 20.1616 50.1235 20.1616C50.0706 20.1616 48.3769 21.973 46.4185 24.2673Z"
                fill="#6c757d"
              />
              <path
                d="M146.083 24.5088C143.543 27.5881 142.907 28.6748 142.907 30.2447V32.2371H146.348H149.788V26.5013C149.788 23.3616 149.682 20.7654 149.524 20.7654C149.418 20.7654 147.883 22.456 146.083 24.5088Z"
                fill="#6c757d"
              />
            </svg>
          </Link>

          <div class="header__meta">
            <svg
              class="header__switch"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24">
              <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z" />
            </svg>
            <Link to="/careerEn" class="header__lang">
              EN
            </Link>
          </div>
        </div>
        <nav class="header__navigation">
          <button class="header__navigation-menu-button" type="button" aria-label="Меню">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul class="header__list">
            <li class="header__item">
              <Link to="/company" class="header__link" href="#company">
                Компания
              </Link>
            </li>
            <li class="header__item">
              <Link to="/services" class="header__link">
                Услуги
              </Link>
            </li>
            <li class="header__item item-blue">
              <Link to="/career" class="header__link">
                Карьера
              </Link>
            </li>
            <li class="header__item">
              <Link to="/cooperation" class="header__link">
                Сотрудничество
              </Link>
            </li>
            <li class="header__item ">
              <a class="header__link">Новости</a>
            </li>
            <li class="header__item ">
              <a class="header__link" onClick={() => setModalActive(true)}>
                Связаться с нами
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <Form active={modalActive} setActive={setModalActive} />
      <main className="career">
        <div className={classes.root}>
          <Accordion className="accordion__item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>Инженер конструктор</Typography>
            </AccordionSummary>
            <AccordionDetails className="accrdion__details">
              <div className="accordion__content">
                <div className="accordion__content-title">Обязанности</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Разработка и проектирование насосных систем, узлов и деталей к ним с
                      использованием автоматизированных и ручных систем проектирования
                    </li>
                    <li className="accordion__list-item">
                      Технологическая проработка изделий на этапе проектирования и участие в
                      разработке технологических процессов при освоении серийного производства
                    </li>
                    <li className="accordion__list-item">
                      Создание виртуальных моделей узлов и деталей насосных систем; конструкторский
                      надзор за изготовлением опытных образцов и постановкой изделий на серийное
                      производство; авторский надзор и участие в запуске производства новых изделий
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Требования</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Высшее профессиональное техническое образование, преимущественно в направлении
                      гидравлика, гидравлические машины
                    </li>
                    <li className="accordion__list-item">
                      Опыт работы инженером конструктором не менее 3 лет (по насосному оборудованию)
                      навыки разработки конструкторской документации на насосы; опыт выполнения
                      расчетов рабочих колес, балансов и.т.д;
                    </li>
                    <li className="accordion__list-item">
                      Опыт проектирования и разработки 3d, моделей насосов
                    </li>
                    <li className="accordion__list-item">
                      Знание аббревиатуры сталей и сплавов по американским и европейским стандартам
                      и их отечественных аналогов
                    </li>
                    <li className="accordion__list-item">
                      Знание и умение работать в программе КОМПАС и SolidWorks
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Условия</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Работа в стабильной компании, интересные и долгосрочные проекты, современный
                      удобный офис в городе;
                    </li>
                    <li className="accordion__list-item">
                      Официальное трудоустройство согласно ТК РФ
                    </li>
                    <li className="accordion__list-item">
                      Пятидневная рабочая неделя (график Пн- Пт: с 9:00 до 18:00)
                    </li>
                    <li className="accordion__list-item">
                      Своевременная выплата заработанной платы (2 раза в месяц)
                    </li>
                    <li className="accordion__list-item">
                      Возможность обучения и повышения квалификации за счет компании. Наши
                      требования: высшее профессиональное техническое образование, преимущественно в
                      направлении гидравлика, гидравлические машины
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion__item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography className={classes.heading}>
                Инженер по подбору и закупкам комплектующих
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accrdion__details">
              <div className="accordion__content">
                <div className="accordion__content-title">Обязанности</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Изучение технической документации Заказчика и подбор насосного оборудования и
                      комплектующих под требования Заказчика
                    </li>
                    <li className="accordion__list-item">Работа с поставщиками</li>
                    <li className="accordion__list-item">
                      Взаимодействие с конструкторскими подразделениями заводов-изготовителей (в том
                      числе переписка на английском языке)
                    </li>
                    <li className="accordion__list-item">
                      Согласование основного и вспомогательного оборудования с Заказчиком и
                      проектными институтами
                    </li>
                    <li className="accordion__list-item">
                      Техническая поддержка и сопровождение проектов, включая командировки на
                      предприятия-изготовители и предприятия Заказчика для инспекции установки
                      оборудования с целью инспекции, проверки соблюдения условий контрактов и
                      участия в проведении испытаний
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Требования</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Опыт инженером-конструктором, инженером по насосному оборудованию в компаниях
                      производителях насосного оборудования
                    </li>
                    <li className="accordion__list-item">
                      Знания основных производителей мирового и внутреннего насосного рынка
                    </li>

                    <li className="accordion__list-item">
                      Навыки делового общения, переписки, умение систематизировать информацию,
                      желание работать в команде
                    </li>
                    <li className="accordion__list-item">Знание тех. английского языка</li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Условия</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Работа в стабильной компании, интересные и долгосрочные проекты, современный
                      удобный офис в городе;
                    </li>
                    <li className="accordion__list-item">
                      Официальное трудоустройство согласно ТК РФ
                    </li>
                    <li className="accordion__list-item">
                      Пятидневная рабочая неделя (график Пн- Пт: с 9:00 до 18:00)
                    </li>
                    <li className="accordion__list-item">
                      Своевременная выплата заработанной платы (2 раза в месяц)
                    </li>
                    <li className="accordion__list-item">
                      Возможность обучения и повышения квалификации за счет компании. Наши
                      требования: высшее профессиональное техническое образование, преимущественно в
                      направлении гидравлика, гидравлические машины
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion__item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header">
              <Typography className={classes.heading}>Сервисный инженер</Typography>
            </AccordionSummary>
            <AccordionDetails className="accrdion__details">
              <div className="accordion__content">
                <div className="accordion__content-title">Обязанности</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Организация и выполнение работ по техническому обслуживанию и ремонту
                      насосного оборудования на производственной площадке заказчика
                    </li>
                    <li className="accordion__list-item">
                      Выполнение работ по полной или частичной разборке, составления дефектовочной
                      спецификации и сборке насоса на производственной площадке заказчика
                    </li>
                    <li className="accordion__list-item">
                      Осуществляет и документирует замеры, в том числе геометрические замеры деталей
                      при необходимости
                    </li>
                    <li className="accordion__list-item">
                      Выполнение индивидуальных и комплексных испытаний на производственной площадке
                      заказчика
                    </li>
                    <li className="accordion__list-item">
                      Координация и контроль работ подрядных и субподрядных организаций на рабочей
                      площадке
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Требования</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Профильное техническое образование, преимущественно направлений,
                      машиностроение, гидравлика, гидравлические машины
                    </li>
                    <li className="accordion__list-item">
                      Опыт работы сервисным инженером не менее 3 лет (по насосному оборудованию)
                    </li>
                    <li className="accordion__list-item">
                      Опыт работы в организациях, занимающихся производством, сервисным
                      обслуживанием промышленных насосов (не менее 1 года)
                    </li>
                    <li className="accordion__list-item">
                      Навыки чтения конструкторской документации и деталировок на насосы
                    </li>
                    <li className="accordion__list-item">
                      Опыт выполнения ремонтов насосного оборудования, работ по ШМР и ПНР
                    </li>
                    <li className="accordion__list-item">
                      Знание типов и конструкций насосного оборудования по американским и
                      европейским стандартам и их отечественных аналогов
                    </li>
                    <li className="accordion__list-item">
                      Знание и умение работать в программе КОМПАС и SOLIDWORKS
                    </li>
                    <li className="accordion__list-item">Готовность к командировкам</li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Условия</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Работа в стабильной компании, интересные и долгосрочные проекты, современный
                      удобный офис в городе;
                    </li>
                    <li className="accordion__list-item">
                      Официальное трудоустройство согласно ТК РФ
                    </li>
                    <li className="accordion__list-item">
                      Пятидневная рабочая неделя (график Пн- Пт: с 9:00 до 18:00)
                    </li>
                    <li className="accordion__list-item">
                      Своевременная выплата заработанной платы (2 раза в месяц)
                    </li>
                    <li className="accordion__list-item">
                      Возможность обучения и повышения квалификации за счет компании. Наши
                      требования: высшее профессиональное техническое образование, преимущественно в
                      направлении гидравлика, гидравлические машины
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion__item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header">
              <Typography className={classes.heading}>
                Менеджер по продаже насосного оборудования
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accrdion__details">
              <div className="accordion__content">
                <div className="accordion__content-title">Обязанности</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Обработка входящих заявок и запросов от клиентов, телефонные переговоры и
                      встречи на выезде
                    </li>
                    <li className="accordion__list-item">
                      Заключение договорных отношений и сопровождение на всех этапах сделки
                    </li>
                    <li className="accordion__list-item">
                      Подготовка и согласование коммерческих предложений и спецификаций
                    </li>
                    <li className="accordion__list-item">
                      Расширение клиентской базы за счет привлечения новых клиентов
                    </li>
                    <li className="accordion__list-item">
                      Постоянное взаимодействие с клиентами, информационная поддержка и решение всех
                      возникающих вопросов
                    </li>
                    <li className="accordion__list-item">
                      Анализ и мониторинг рынка, ведение отчетной документации по продажам (в т.ч. в
                      CRM)
                    </li>
                    <li className="accordion__list-item">Взаимодействие со смежными отделами</li>
                    <li className="accordion__list-item">Работа с рекламациями</li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Требования</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Высшее образование и опыт работы на аналогичной должности от 3-х лет
                    </li>
                    <li className="accordion__list-item">
                      Знание технических характеристик насосного оборудования, конструкции насосов и
                      рынка потенциальных клиентов будет Вашим преимуществом
                    </li>
                    <li className="accordion__list-item">Опыт работы в CRM системе</li>
                    <li className="accordion__list-item">
                      Навыки ведения переговоров, умение убеждать и работать с возражениями
                    </li>
                    <li className="accordion__list-item">Готовность к командировкам</li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Условия</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Официальное трудоустройство согласно ТК РФ
                    </li>
                    <li className="accordion__list-item">
                      Заработную плату: оклад + честный % (в среднем на старте от 80.000 рублей)
                    </li>
                    <li className="accordion__list-item">Дружный коллектив</li>
                    <li className="accordion__list-item">
                      Интересную работу в динамично развивающейся и надежной компании
                    </li>
                    <li className="accordion__list-item">Бесплатное корпоративное обучение</li>
                    <li className="accordion__list-item">Реалистичные планы продаж</li>
                    <li className="accordion__list-item">
                      Комфортный офис и оборудованное рабочее место
                    </li>
                    <li className="accordion__list-item">
                      Возможность возглавить новые проекты и направления
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion__item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header">
              <Typography className={classes.heading}>Инженер по подбору насосов</Typography>
            </AccordionSummary>
            <AccordionDetails className="accrdion__details">
              <div className="accordion__content">
                <div className="accordion__content-title">Обязанности</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Подготовка РКД и технической части ТКП на лопастные насосные агрегаты и
                      насосные агрегаты других типов
                    </li>
                    <li className="accordion__list-item">
                      Изучение технической документации Заказчика и подбор насосного оборудования и
                      комплектующих под требования Заказчика
                    </li>
                    <li className="accordion__list-item">
                      Работа с поставщиками вспомогательного оборудования (КИПиА, электродвигатели,
                      торцовые уплотнения и пр.)
                    </li>
                    <li className="accordion__list-item">
                      Взаимодействие с конструкторскими подразделениями заводов-изготовителей (в том
                      числе переписка на английском языке)
                    </li>
                    <li className="accordion__list-item">
                      Согласование основного и вспомогательного оборудования с Заказчиком и
                      проектными институтами;Разработка конструкторской и сопроводительной
                      документации ( AutoCad и SolidWorks) в соответствие с требованиями мировых,
                      российских стандартов и с учетом требований заказчиков
                    </li>
                    <li className="accordion__list-item">
                      Техническая поддержка и сопровождение проектов, включая командировки на
                      предприятия-изготовители и предприятия Заказчика для инспекции установки
                      оборудования с целью инспекции, проверки соблюдения условий контрактов и
                      участия в проведении испытаний
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Требования</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Опыт инженером-конструктором, инженером по насосному оборудованию в компаниях
                      производителях насосного оборудования
                    </li>
                    <li className="accordion__list-item">
                      Знания основных производителей мирового и внутреннего насосного рынка
                    </li>
                    <li className="accordion__list-item">
                      Навыки разработки конструкторской документации на лопастные насосы, знание
                      принципов работы лопастных насосов и насосов других типов
                    </li>
                    <li className="accordion__list-item">
                      Навыки обоснования и защиты проекта и на технических совещаниях с проектными
                      институтами и представителями Заказчика
                    </li>
                    <li className="accordion__list-item">
                      Навыки делового общения, переписки, умение систематизировать информацию,
                      желание работать в команде
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion__content">
                <div className="accordion__content-title">Условия</div>
                <div className="accordion__content-text-part">
                  <ul>
                    <li className="accordion__list-item">
                      Работа в стабильной компании, интересные и долгосрочные проекты, современный
                      удобный офис в городе;
                    </li>
                    <li className="accordion__list-item">
                      Официальное трудоустройство согласно ТК РФ
                    </li>
                    <li className="accordion__list-item">
                      Пятидневная рабочая неделя (график Пн- Пт: с 9:00 до 18:00)
                    </li>
                    <li className="accordion__list-item">
                      Своевременная выплата заработанной платы (2 раза в месяц)
                    </li>
                    <li className="accordion__list-item">
                      Возможность обучения и повышения квалификации за счет компании. Наши
                      требования: высшее профессиональное техническое образование, преимущественно в
                      направлении гидравлика, гидравлические машины
                    </li>
                  </ul>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Career;
